<template>
    <div class="pagebox">

        <div class="download_box" id="wish_pdf_box">
            <!-- 信息区域 -->
            <div class="wish_table">
                <div class="wish_box">
                    <div class="mark">
                        <span>注：以上志愿表为模拟填报志愿表，正式填报请登录省考试院指定填报网站</span>
                        <span>申明：使用当年招生计划和参考历年录取数据的志愿模拟填报平台，参考数据均来自省考试院，正式填报过程中如遇到数据错误，请参阅省考试院发布的相关招生计划书籍</span>
                    </div>
                    <div class="userbox">
                        <div class="type">
                            <span class="vip"><i class="iconfont">&#xe6ba;</i>{{User.wishCategory === 1?'位点法':'位次法'}}</span>
                            <span class="tname">志愿表 </span>
                            <span class="yname">{{User.Year}}年 {{User.ProvinceName}} 高考志愿模拟填报</span>
                        </div>
                        <div class="info">
                            <span class="exam">
                                <span>我的成绩：{{User.Score}} 分</span>
                                <span>批次：{{SelectRecommendBatch.BatchName}}</span>
                                <span v-if="RecommendBatch.Rank">位次：{{RecommendBatch.Rank||'-'}}</span>
                            </span>
                        </div>
                    </div>
                    <div class="theadtitle"><span>您填报的志愿数量为 <b>{{ReportCheckData.length}}</b> 个</span></div>
                </div>
            </div>

            <!-- 志愿列表（院校+专业） -->
            <div class="wish_t_h" v-if="CollegeOrMajorGroupId==1">
                <div class="wish_t_fixed" :class="TableHeaderFixed">
                    <div class="wish_head">
                        <div class="htable">
                            <div class="head">
                                <div class="td">院校</div>
                                <div class="td">专业</div>
                                <div class="td">{{SelectRecommendBatch.PlanYear}} 招生计划</div>
                                <div v-if="SelectRecommendBatch.YearList" class="td">
                                    <div class="t">历年录取</div>
                                    <div class="year">
                                        <div class="y">{{SelectRecommendBatch.YearList[0]}}年</div>
                                        <div class="y">{{SelectRecommendBatch.YearList[1]}}年</div>
                                        <div class="y">{{SelectRecommendBatch.YearList[2]}}年</div>
                                    </div>
                                </div>
                                <div class="clear"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wish_t_l" v-if="CollegeOrMajorGroupId==1">
                <div class="wish_t_m">
                    <div class="wtable">
                        <div class="table">
                            <table cellspacing="0" style="width:100%;">
                                <tr v-for="item in ReportCheckData" :key="item.RecommendCollegeId">
                                    <td>
                                        <div class="c_name">{{item.CollegeName}}</div>
                                        <span v-show="item.CollegeRankNum" class="tips-words">{{item.CollegeRankNum+'['+item.CollegeRankCategoryName+']'}}</span>
                                        <div class="s_txt">
                                            <span>代码：{{item.CollegeCode}}</span>
                                            <span v-show="item.GroupCode">专业组代码：{{ item.GroupCode }}</span>
                                            <span v-if="User.wishCategory===1">预测分：{{item.PredictScore?item.PredictScore+'分':'-'}}</span>
                                            <span v-else>录取概率：{{item.AdmissionProbability?item.AdmissionProbability+'%':'-'}}</span>
                                            <div class="tips-words">
                                                <span v-show="item.PostgraduateRate">读研率：{{ item.PostgraduateRate }}%</span>
                                                <span v-show="item.PostgraduateRecommendationRate">保研率：{{ item.PostgraduateRecommendationRate }}%</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <table cellspacing="0" style="width:100%;">
                                            <tr v-for="majorItem in item.SelectMajor" :key="majorItem.RecommendMajorId">
                                                <td>
                                                    <div class="major">
                                                        <div class="name">{{majorItem.MajorName}}</div>
                                                        <div class="mx">{{majorItem.MajorRemark}}</div>
                                                        <div class="mx"><span>代码：{{majorItem.MajorCode}}</span>
                                                            <span v-if="User.wishCategory===1">预测分：{{majorItem.PredictScore?majorItem.PredictScore+'分':'-'}}</span>
                                                        <span v-else>录取概率：{{majorItem.AdmissionProbability?majorItem.AdmissionProbability+'%':'-'}}</span>
                                                        <div class="tips-words">
                                                            <span v-show="majorItem.PostgraduateRate">读研率：{{ majorItem.PostgraduateRate }}%</span>
                                                            <span v-show="majorItem.PostgraduateRecommendationRate">保研率：{{ majorItem.PostgraduateRecommendationRate }}%</span>
                                                            <span v-show="majorItem.IsPostgraduateMaster">硕士点：{{ majorItem.IsPostgraduateMaster }}</span>
                                                            <span v-show="majorItem.IsPostgraduateDoctoral">博士点：{{ majorItem.IsPostgraduateDoctoral }}</span>
                                                            <span v-show="majorItem.MajorRankNum">专业排名：{{majorItem.MajorRankNum}}</span>
                                                            <span v-show="majorItem.LearnSubjectEvaluateLevel" class="evaluate">学科评估：{{majorItem.LearnSubjectEvaluateLevel}}</span>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div v-show="majorItem.PlanPersons" class="person"><span>{{majorItem.PlanPersons}}</span> 人</div>
                                                    <div>
                                                        <span v-show="majorItem.LearningYears" class="md">{{majorItem.LearningYears}}年</span>
                                                        <span v-show="majorItem.LearningCost" class="md"> ￥{{majorItem.LearningCost}}</span>
                                                    </div>
                                                    <div v-show="majorItem.SubjectsCondition" class="md">{{majorItem.SubjectsCondition}}</div>
                                                </td>
                                                <td>
                                                    <div class="ls">
                                                        <span>录取人数：{{majorItem.PersonsOfYear1}}</span>
                                                        <span>最低分：{{majorItem.ScoreLowOfYear1}}</span>
                                                        <span>最低位次：{{majorItem.RankLowOfYear1}}</span>
                                                        <span class="tips">
                                                            <span>等效分差：{{ majorItem.PersonalScoreDiffOfYear1 }}</span>
                                                        </span>
                                                        <!-- <span>平均分：{{majorItem.ScoreAvgOfYear1}}</span>
                                                        <span>平均位次：{{majorItem.RankAvgOfYear1}}</span> -->
                                                    </div>
                                                    <div class="ls">
                                                        <span>录取人数：{{majorItem.PersonsOfYear2}}</span>
                                                        <span>最低分：{{majorItem.ScoreLowOfYear2}}</span>
                                                        <span>最低位次：{{majorItem.RankLowOfYear2}}</span>
                                                        <span class="tips">
                                                            <span>等效分差：{{ majorItem.PersonalScoreDiffOfYear2 }}</span>
                                                        </span>
                                                        <!-- <span>平均分：{{majorItem.ScoreAvgOfYear2}}</span>
                                                        <span>平均位次：{{majorItem.RankAvgOfYear2}}</span> -->
                                                    </div>
                                                    <div class="ls">
                                                        <span>录取人数：{{majorItem.PersonsOfYear3}}</span>
                                                        <span>最低分：{{majorItem.ScoreLowOfYear3}}</span>
                                                        <span>最低位次：{{majorItem.RankLowOfYear3}}</span>
                                                        <span class="tips">
                                                            <span>等效分差：{{ majorItem.PersonalScoreDiffOfYear3 }}</span>
                                                        </span>
                                                        <!-- <span>平均分：{{majorItem.ScoreAvgOfYear3}}</span>
                                                        <span>平均位次：{{majorItem.RankAvgOfYear3}}</span> -->
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                            <div class="nowish" v-show="false">暂无数据！</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 志愿列表（专业+院校） -->
            <div class="wish_t_h" v-if="CollegeOrMajorGroupId==2">
                <div class="wish_t_fixed" :class="TableHeaderFixed">
                    <div class="wish_head">
                        <div class="htable">
                            <div class="head">
                                <div class="td">专业</div>
                                <div class="td">院校</div>
                                <div class="td">{{SelectRecommendBatch.PlanYear}} 招生计划</div>
                                <div v-if="SelectRecommendBatch.YearList" class="td">
                                    <div class="t">历年录取</div>
                                    <div class="year">
                                        <div class="y">{{SelectRecommendBatch.YearList[0]}}年</div>
                                        <div class="y">{{SelectRecommendBatch.YearList[1]}}年</div>
                                        <div class="y">{{SelectRecommendBatch.YearList[2]}}年</div>
                                    </div>
                                </div>
                                <div class="clear"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wish_t_l" v-if="CollegeOrMajorGroupId==2">
                <div class="wish_t_m">
                    <div class="wtable">
                        <div class="table majorgroup">
                            <table cellspacing="0" style="width:100%;">
                                <tr v-for="item in ReportCheckData" :key="item.RecommendMajorId">
                                    <td>
                                        <div class="major">
                                            <div class="name">{{item.MajorName}}</div>
                                            <div class="mx">{{item.MajorRemark}}</div>
                                            <div class="mx"><span>代码：{{item.MajorCode}}</span>
                                                <span v-if="User.wishCategory===1">预测分：{{item.PredictScore?item.PredictScore+'分':'-'}}</span>
                                                <span v-else>录取概率：{{item.AdmissionProbability?item.AdmissionProbability+'%':'-'}}</span>
                                                <div class="tips-words">
                                                    <span v-show="item.PostgraduateRate">读研率：{{ item.PostgraduateRate }}%</span>
                                                    <span v-show="item.PostgraduateRecommendationRate">保研率：{{ item.PostgraduateRecommendationRate }}%</span>
                                                    <span v-show="item.IsPostgraduateMaster">硕士点：{{ item.IsPostgraduateMaster }}</span>
                                                    <span v-show="item.IsPostgraduateDoctoral">博士点：{{ item.IsPostgraduateDoctoral }}</span>
                                                    <span v-show="item.MajorRankNum">专业排名：{{item.MajorRankNum}}</span>
                                                    <span v-show="item.LearnSubjectEvaluateLevel" class="evaluate">学科评估：{{item.LearnSubjectEvaluateLevel}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="c_name">{{item.CollegeName||(item.FromCollege&&item.FromCollege.CollegeName)||''}}</div>
                                        <span v-show="item.CollegeRankNum" class="tips-words">{{item.CollegeRankNum+'['+item.CollegeRankCategoryName+']'}}</span>
                                        <div class="s_txt">
                                            <span>代码：{{item.CollegeCode||(item.FromCollege&&item.FromCollege.CollegeCode)||''}}</span>
                                            <span class="types">{{handleNature(item)}}</span>
                                            <span class="tipss nowrap" :title="item.CollegeStrengthTags||(item.FromCollege&&item.FromCollege.CollegeStrengthTags)||''">
                                                <span v-for="tagItem in handleTag(item)" :key="tagItem">{{tagItem}}</span>
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div v-show="item.PlanPersons" class="person"><span>{{item.PlanPersons}}</span> 人</div>
                                        <div>
                                            <span v-show="item.LearningYears" class="md">{{item.LearningYears}}年</span>
                                            <span v-show="item.LearningCost" class="md"> ￥{{item.LearningCost}}</span>
                                        </div>
                                        <div v-show="item.SubjectsCondition" class="md">{{item.SubjectsCondition}}</div>
                                    </td>
                                    <td>
                                        <div class="ls">
                                            <span>录取人数：{{item.PersonsOfYear1}}</span>
                                            <span>最低分：{{item.ScoreLowOfYear1}}</span>
                                            <span>最低位次：{{item.RankLowOfYear1}}</span>
                                            <span class="tips">
                                                <span>等效分差：{{ item.PersonalScoreDiffOfYear1 }}</span>
                                            </span>
                                            <span>平均分：{{item.ScoreAvgOfYear1}}</span>
                                            <span>平均位次：{{item.RankAvgOfYear1}}</span>
                                        </div>
                                        <div class="ls">
                                            <span>录取人数：{{item.PersonsOfYear2}}</span>
                                            <span>最低分：{{item.ScoreLowOfYear2}}</span>
                                            <span>最低位次：{{item.RankLowOfYear2}}</span>
                                            <span class="tips">
                                                <span>等效分差：{{ item.PersonalScoreDiffOfYear2 }}</span>
                                            </span>
                                            <span>平均分：{{item.ScoreAvgOfYear2}}</span>
                                            <span>平均位次：{{item.RankAvgOfYear2}}</span>
                                        </div>
                                        <div class="ls">
                                            <span>录取人数：{{item.PersonsOfYear3}}</span>
                                            <span>最低分：{{item.ScoreLowOfYear3}}</span>
                                            <span>最低位次：{{item.RankLowOfYear3}}</span>
                                            <span class="tips">
                                                <span>等效分差：{{ item.PersonalScoreDiffOfYear3 }}</span>
                                            </span>
                                            <span>平均分：{{item.ScoreAvgOfYear3}}</span>
                                            <span>平均位次：{{item.RankAvgOfYear3}}</span>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <div class="nowish" v-show="false">暂无数据！</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
import {downloadPDF} from "@/utils/pdf-wish.js";  //转换PDF
import { getStore} from "@/utils/utils";
export default {
    data() {
        return{
            TableHeaderFixed:'',
            CollegeOrMajorGroupId:2,

            LoginInfo: null,
            User: {},
            RecommendBatch: {},
            SelectRecommendBatch: {},
            ProvinceObj: {},
            SelectWishWayObj: {},

            ReportCheckData: [],

            SaveQuery: {}
        }
    },
    mounted(){
        this.initial()
    },
    methods:{
        initial() {
            this.getInfo()

            setTimeout(() => {

                if(this.$route.query.type==1){
                    downloadPDF();// PDF
                }
                else{
                    this.$print(document.getElementById('wish_pdf_box'));// 打印
                }

            }, 500)
            
        },
        getInfo() {
            // 获取推荐批次
            let batch = getStore('RecommendBatch')
            this.RecommendBatch = JSON.parse(batch)
            
            let selectBatch = getStore('SelectRecommendBatch')
            this.SelectRecommendBatch = JSON.parse(selectBatch)

            // 获取选中省份
            let province = getStore('OrgProvince')
            if (!province) {
                this.$message.error('未获取到省份数据！')
                return false
            }
            this.ProvinceObj = JSON.parse(province)
            // 判断是院校专业组还是专业院校组
            
            
            this.SelectWishWayObj = this.ProvinceObj.WishWayDic[this.SelectRecommendBatch.BatchId]
            this.CollegeOrMajorGroupId = this.SelectWishWayObj.WishWay

            // login info
            let login = getStore('LoginData')
            if (login) {
                this.LoginInfo = JSON.parse(login)
            }
            // 获取个人信息
            let tempUser = getStore('studentReport')
            this.User = JSON.parse(tempUser)

            // 已填列表数据
            let ReportCheckDataObj = getStore('ReportCheckDataObj')

            if(ReportCheckDataObj) {
                this.ReportCheckData = JSON.parse(ReportCheckDataObj)[this.SelectWishWayObj.WishWay]
            }
        },

        // 标签
        handleTag(item) {
            let temp = item.CollegeStrengthTags || (item.FromCollege && item.FromCollege.CollegeStrengthTags)
            return temp ? temp.split(','):[]
        },
        handleNature(item) {
            let temp1 = item.CollegeProvinceName || (item.FromCollege && item.FromCollege.CollegeProvinceName)
            let temp2 = item.CollegeCityName || (item.FromCollege && item.FromCollege.CollegeCityName)
            let temp3 = item.CollegeCategoryName || (item.FromCollege && item.FromCollege.CollegeCategoryName)
            let temp4 = item.CollegeNatureName || (item.FromCollege && item.FromCollege.CollegeNatureName)

            let data = `${temp1||''} · ${temp2||''} · ${temp3||''} · ${temp4||''}`
            return data
        },
    }
}
</script>
<style scoped lang="less">
.wish_table{
    max-width: 1020px;
    margin: 20px auto 0px;
    user-select: none;
    .wish_box{
        margin: 0px 20px;
        border: 1px solid #dfdddd;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom: none;
        .mark{
            border: 1px dashed rgba(var(--themecolor),0.6);
            border-radius: 5px;
            padding: 5px 0;
            margin: 15px;
            background-color: #fff;
            span{
                display: inline-block;
                margin: 3px 15px;
                color: #777;
                font-size: 13px;
            }
        }
        .userbox{
            margin: 20px 20px;
            background-color: #fff;
            border-radius: 5px;
            .type{
                padding: 20px 20px 0;
                width: calc(100% - 468px);
                display: inline-block;
                .vip{
                    border: 3px solid rgba(var(--themecolor),0.3);
                    color: #da8824;
                    font-size: 16px;
                    padding: 2px 15px;
                    border-radius: 5px;
                    display: inline-block;
                    letter-spacing: 2px;
                    font-weight: bold;
                    font-style: italic;
                    position: relative;
                    i{
                        text-align: center;
                        color: #e6b738;
                        font-size: 20px;
                        margin-right: 5px;
                        position: absolute;
                        left: -11px;
                        top: -16px;
                        z-index: 1;
                    }
                }
                .tname{
                    font-size: 20px;
                    color: #333;
                    margin-left: 20px;
                    span{
                        color: #666;
                    }
                }
                .yname{
                    font-size: 16px;
                    color: #555;
                    margin-left: 20px;
                }
            }
            .info{
                width: calc(100% - 468px);
                margin: 20px 20px 20px;
                padding-bottom: 10px;
                display: inline-block;
                .exam{
                    font-size: 14px;
                    color: #555;
                    span{
                        margin-right: 20px;
                    }
                }
            }
            .bott{
                width: 428px;
                height: 40px;
                float: right;
                span{
                    height: 40px;
                    line-height: 40px;
                    border-radius: 5px;
                    margin: 0px 10px;
                    background-color: rgba(var(--themecolor),0.8);
                    color: #fff;
                    font-size: 14px;
                    display: inline-block;
                    padding: 0 20px;
                    cursor: pointer;
                    i{
                        margin-right: 5px;
                    }
                }
                span:hover{
                    background-color: rgba(var(--themecolor),1);
                }
            }
        }
        .theadtitle{
            margin: 0 20px;
            padding-bottom: 10px;
            span{
                color: #666;
                font-size: 14px;
                b{
                    color: rgba(var(--themecolor),1);
                    font-size: 18px;
                }
            }
        }
    }
}
.wish_t_h{
    width: 100%;
    height: 50px;
    .wish_t_fixed{
        width: 100%;
        height: 50px;
        .wish_head{
            max-width: 1020px;
            margin: auto;
            user-select: none;
            .htable{
                margin: auto 20px;
                border-left: 1px solid #ddd;
                border-right: 1px solid #ddd;
                .head{
                    margin: 0px 20px;
                    background-color: #f5ecec;
                    height: 49px;
                    line-height: 49px;
                    color: #555;
                    font-size: 15px;
                    border-bottom: 1px solid #ddd;
                    border: 1px solid #ddd;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    .td{
                        text-align: center;
                        border-left: 1px solid #ddd;
                        float: left;
                        .t{
                            border-bottom: 1px solid #ddd;
                            height: 24px;
                            line-height: 24px;
                        }
                        .year{
                            height: 25px;
                            line-height: 25px;
                            .y{
                                float: left;
                                width: calc((100% - 2px) / 3);
                                border-left: 1px solid #ddd;
                                font-size: 14px;
                                color: #777;
                            }
                            .y:first-child{
                                border-left:none;
                            }
                        }
                    }
                    .td:nth-child(1){
                        width: 210px;
                        border-left: none;
                    }
                    .td:nth-child(2){
                        width: 200px;
                    }
                    .td:nth-child(3){
                        width: 150px;
                    }
                    .td:nth-child(4){
                        width: calc(100% - 563px);
                    }
                    .td:nth-child(5){
                        width: 120px;
                    }
                }
                .majorgroup{
                    .td:nth-child(1){
                        width: 200px;
                        border-left: none;
                    }
                    .td:nth-child(2){
                        width: 200px;
                    }
                    .td:nth-child(3){
                        width: 150px;
                    }
                    .td:nth-child(4){
                        width: calc(100% - 553px);
                    }
                    .td:nth-child(5){
                        width: 120px;
                    }
                }
            }
        }
    }
    .wish_t_fixed.fixed{
        position: fixed;
        top: 40px;
        left: 0px;
        z-index: 99;
    }
}
.wish_t_l{
    width: 100%;
    .wish_t_m{
        max-width: 1020px;
        margin: auto;
        user-select: none;
        .wtable{
            margin: 0px 20px 50px;
            border: 1px solid #ddd;
            border-top: none;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            .table{
                margin: 0px 20px 20px;
                border: 1px solid #ddd;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                background-color: #fff;
                tr{
                    color: #666;
                    font-size: 14px;
                    td{
                        padding: 0 5px;
                        border-left: 1px solid #ddd;
                        border-bottom: 1px solid #ddd;
                        .c_name{
                            color: #555;
                            font-size: 20px;
                            line-height: 24px;
                        }
                        .s_txt{
                            margin-top: 10px;
                            font-size: 12px;

                            // overflow: hidden;
                            // text-overflow: ellipsis;
                            
                            span{
                                margin: 5px 5px;
                                // display: block;
                            }
                            .types{
                                font-size: 12px;
                                display: block;
                                line-height: 24px;
                            }
                            .tipss{
                                font-size: 12px;
                                color: #888;
                                margin-top: 5px;
                                display: block;
                                span{
                                    padding: 0 5px;
                                    border-left: 1px solid #ddd;
                                    display: inline-block;
                                    height: 12px;
                                    line-height: 12px;
                                }
                                span:first-child{
                                    border-left:none;
                                    padding-left: 0;
                                }
                            }
                        }
                        .num{
                            display: inline-block;
                            width:36px;
                            height: 18px;
                            text-align: center;
                            line-height: 18px;
                            background-color: #fff;
                            color: rgba(var(--themecolor),1);
                            font-size: 14px;
                            border: 1px solid rgba(var(--themecolor),0.25);
                            border-radius: 3px;
                            overflow: hidden;
                            i{
                                font-size: 12px;
                                color: #999;
                                margin-left: 2px;
                            }
                        }
                        .num:hover{
                            cursor: pointer;
                            border: 1px solid rgba(var(--themecolor),0.4);
                        }
                        .major{
                            padding: 10px;
                            .name{
                                color: #555;
                                font-size: 18px;
                                line-height: 22px;
                                margin-bottom: 10px;
                            }
                            .mx{
                                font-size: 12px;
                                margin: 5px 0;
                                span{
                                    margin-right:10px;
                                }
                            }
                        }
                    }
                    td:nth-child(1){
                        width: 200px;
                        padding: 5px 5px;
                        border-left:none;
                        text-align: center;
                        position: relative;
                    }
                    td:nth-child(2){
                        width: calc(100% - 200px);
                        padding: 0;
                        tr:last-child td{
                            border-bottom:none;
                        }
                        td{
                            border-bottom: 1px solid #ddd;
                            .person{
                                color: #777;
                                font-size: 12px;
                                margin-bottom: 10px;
                                span{
                                    color: #555;
                                    font-size: 18px;
                                }
                            }
                            .md{
                                color: #888;
                                font-size: 12px;
                            }
                        }
                        td:nth-child(1){
                            width: 190px;
                        }
                        td:nth-child(2){
                            width: 150px;
                            text-align: center;
                        }
                        td:nth-child(3){
                            padding: 20px 0;
                            width: calc(100% - 340px);
                            .ls{
                                width: calc((100% - 2px) / 3);
                                display: inline-block;
                                border-left: 1px dashed #b8b8b8;
                                span{
                                    display: block;
                                    line-height: 20px;
                                    text-align: center;
                                    color: #666;
                                    font-size: 12px;
                                }
                            }
                            .ls:first-child{
                                border-left:none;
                            }

                            .tips {
                                display: flex;
                                span {
                                    display: inline;
                                }
                            }
                            .tips-text {
                                position: relative;
                                .icon {
                                    color: #999;
                                }
                                &:hover {
                                    cursor: default;
                                    .show-text {
                                        display: block;
                                        cursor: default;
                                    }
                                }
                                .show-text {
                                    display: none;
                                    width: 200px;
                                    padding: 5px;
                                    border-radius: 5px;
                                    background-color: rgba(0,0,0,0.5);
                                    color: #fff;
                                    position: absolute;
                                    top: -88px;
                                    left: -100px;
                                    z-index: 2;
                                }
                            }
                            
                        }
                        td:nth-child(4){
                            width: 30px;
                            text-align: center;
                            i.el-icon-top,i.el-icon-bottom{
                                display: inline-block;
                                font-size: 18px;
                                color: #999;
                                width: 22px;
                                height: 22px;
                                line-height: 22px;
                            }
                            i.el-icon-top:hover,i.el-icon-bottom:hover{
                                cursor: pointer;
                                color: rgba(var(--themecolor),1);
                            }
                            i.el-icon-top{
                                margin-bottom: 5px;
                            }
                            i.el-icon-bottom{
                                margin-top: 5px;
                            }
                            i.el-icon-delete{
                                width: 22px;
                                height: 22px;
                                line-height: 22px;
                                font-size: 14px;
                                margin-top: 8px;
                                color: #999;
                                display: inline-block;
                                cursor: pointer;
                            }
                            i.el-icon-delete:hover{
                                color: rgba(var(--themecolor),1);
                            }
                        }
                    }
                }
                tr:last-child td{
                    border-bottom:none;
                }
            }
            .majorgroup{
                tr td{
                    text-align: center;
                    .person{
                        color: #777;
                        font-size: 12px;
                        margin-bottom: 10px;
                        span{
                            color: #555;
                            font-size: 18px;
                        }
                    }
                    .md{
                        color: #888;
                        font-size: 12px;
                    }
                }
                tr td:nth-child(1){
                    width: 190px;
                }
                tr td:nth-child(2){
                    width: 200px;
                }
                tr td:nth-child(3){
                    width: 140px;
                }
                tr td:nth-child(4){
                    padding: 10px 0;
                    width: calc(100% - 850px);
                    .ls{
                        width: calc((100% - 2px) / 3);
                        display: inline-block;
                        border-left: 1px dashed #b8b8b8;
                        span{
                            display: block;
                            line-height: 20px;
                            text-align: center;
                            color: #666;
                            font-size: 12px;
                        }
                    }
                    .ls:first-child{
                        border-left:none;
                    }
                    .tips {
                        display: flex;
                        span {
                            display: inline;
                        }
                    }
                    .tips-text {
                        position: relative;
                        .icon {
                            color: #999;
                        }
                        &:hover {
                            cursor: default;
                            .show-text {
                                display: block;
                                cursor: default;
                            }
                        }
                        .show-text {
                            display: none;
                            width: 200px;
                            padding: 5px;
                            border-radius: 5px;
                            background-color: rgba(0,0,0,0.5);
                            color: #fff;
                            position: absolute;
                            top: -88px;
                            left: -100px;
                            z-index: 2;
                        }
                    }
                }
            }
            .nowish{
                width: 100%;
                text-align: center;
                line-height: 300px;
                color: #666;
                font-size: 16px;
            }
        }
    }
}
</style>
<style lang="less">
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button:focus, .el-message-box__wrapper .el-message-box .el-message-box__btns .el-button:hover {
  color: rgba(var(--themecolor),0.75) !important;
  border-color: rgba(var(--themecolor),0.35) !important;
  background-color: rgba(var(--themecolor),0.01) !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary{
    background-color: rgba(var(--themecolor),0.75) !important;
    border-color: rgba(var(--themecolor),0.75) !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary:focus,.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary:hover{
    background-color: rgba(var(--themecolor),0.95) !important;
    border-color: rgba(var(--themecolor),0.95) !important;
    color: #fff !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__header .el-message-box__headerbtn .el-message-box__close:hover{
    color: rgba(var(--themecolor),0.95) !important;
}
.el-message-box__wrapper .el-message-box .el-input.is-active .el-input__inner,.el-message-box__wrapper .el-message-box .el-input__inner:focus{
    border-color: #ddd !important;
}
</style>